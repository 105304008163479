import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { get } from 'lodash'

import WishlistPersist from 'Services/WishlistPersist'
import { _ } from 'Services/I18n'
import { PUBLIC_PATHS } from 'Constants/paths'
import Labels from 'Constants/labels'

import { useBaseTheme } from 'Hooks'
import { Box, Flex } from 'Components/UI'

import { WishlistIcon } from 'Components/Blocks/WishlistToggle/styles'

import {
  BusinessName,
  Button,
  Container,
  FindOutMoreIcon,
  MerchantLogo,
  Well,
} from './styles'

import { ContentTitle, Dash } from '../../styles'

import Description from './Description'

const Merchant = ({ business, children, ...rest }) => {
  const history = useHistory()
  const { labels, primaryColor, marketplaceType } = useBaseTheme()

  const wishlistPersist = new WishlistPersist()
  const dispatch = useDispatch()
  const [isWishlisted, setIsWishlisted] = useState(
    wishlistPersist.merchantExists(business.token),
  )

  const handleGoToMerchant = useCallback(() => {
    const path = PUBLIC_PATHS.MERCHANT({
      id: business.token,
      slug: business.slug,
      marketplaceType,
    })

    history.push(path, {
      ...history.location.state,
      prevPath: history.location.pathname,
    })
  }, [business])

  const handleToggleWishlist = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()

      setIsWishlisted(wishlistPersist.toggleMerchant(business.token, dispatch))
    },
    [business],
  )

  return (
    <Box {...rest}>
      <ContentTitle>
        {get(labels, Labels.TITLE_SERVICE_ORGANISED_BY, 'Organised by')}
      </ContentTitle>
      <Dash />
      <Container>
        <Flex
          alignItems={['center', 'center', 'start']}
          flex="1"
          flexDirection="column"
        >
          <BusinessName color={primaryColor} onClick={handleGoToMerchant}>
            {business.name}
          </BusinessName>
          <Flex mt={12}>{children}</Flex>
        </Flex>
        <MerchantLogo src={business.logo} onClick={handleGoToMerchant} />
      </Container>

      <Description description={business.description} mt="12px" />

      <Well mt={3}>
        <Button onClick={handleGoToMerchant}>
          <FindOutMoreIcon mr={2} />
          {_('common.findMore')}
        </Button>

        <Button onClick={handleToggleWishlist}>
          <WishlistIcon active={isWishlisted ? 1 : 0} mr={2} size={16} />
          {isWishlisted ? _('wishlist.wishlisted') : _('wishlist.wishlist')}
        </Button>
      </Well>
    </Box>
  )
}

Merchant.defaultProps = {
  children: null,
}

Merchant.propTypes = {
  business: PropTypes.object.isRequired,
  children: PropTypes.node,
}

export default Merchant
