import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

import { Flex } from 'Components/UI'
import { mapToTheme } from 'styled-map'

export const OptionsWrapper = styled(Flex)`
  flex-direction: column;
  gap: 24px;
`

export const OptionContainer = styled(Flex).attrs({
  px: [3, 3, 4],
  py: [3, 3, '28px'],
})`
  flex-direction: column;
  gap: 12px;

  background-color: ${themeGet('colors.pearl')};
  box-shadow: inset 0 0 10px #f2f2f2;
  border-radius: ${themeGet('borderRadius.1')};
`

export const CategoryContainer = styled(Flex).attrs({
  p: 3,
})`
  background-color: ${themeGet('colors.white')};
  border-radius: ${themeGet('borderRadius.1')};
  gap: 12px;
`

export const CategoryName = styled(Flex).attrs({
  flex: 1,
})`
  font-size: ${({ fontSize }) => fontSize || '14px'};
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`

export const PriceHolder = styled(Flex).attrs({})`
  gap: 4px;
`

export const PriceWrapper = styled(Flex).attrs({
  alignItems: 'flex-end',
})`
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  grid-column-gap: 4px;
  font-weight: bold;
  letter-spacing: 0.44px;
  color: ${({ color }) => color || mapToTheme('colors.font.primary')};
  font-size: 14px;
`

export const PriceLabel = styled(Flex).attrs({
  alignItems: 'center',
})`
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.44px;
  color: ${themeGet('colors.text')};
  white-space: nowrap;
  margin-left: 4px;
`

export const PriceCross = styled(Flex).attrs({})`
  color: ${themeGet('colors.text')};
  font-weight: normal;
  text-decoration: line-through;
  font-size: 14px;
`
