import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Box, Flex, Text as BaseText, Button } from 'Components/UI'
import { mapToTheme } from 'styled-map'

export const Container = styled(Box).attrs({})`
  margin-bottom: 32px;
  width: 360px;
  min-width: 360px;
  height: fit-content;
  position: sticky;
  top: 74px;

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    width: 100%;
    min-width: 100%;
  }
`

export const GroupedServicesTitleText = styled(BaseText).attrs({
  fontSize: 14,
  mb: 3,
  px: 3,
  py: 3,
})`
  line-height: 1.4;
  background-color: #eff7ff;
  color: #00447b;
  border: 1px solid #d6eaff;
  border-radius: ${themeGet('borderRadius.1')};
`

export const PricingWrapper = styled(Flex).attrs({
  p: '24px',
  className: 'pricing-wrapper',
})`
  flex-direction: column;
  align-items: center;
  gap: 16px;

  background-color: ${themeGet('colors.white')};
  border: 1px solid ${themeGet('colors.lightGrey')};
  border-radius: ${themeGet('borderRadius.1')};

  font-weight: bold;
  letter-spacing: 0.44px;
  font-size: 14px;
  color: ${({ color }) => color || mapToTheme('colors.font.primary')};
`

export const PriceWrapper = styled(Flex)`
  justify-content: center;
  width: 100%;
  padding: 12px;
  background-color: ${themeGet('colors.pearl')};
  box-shadow: inset 0 0 10px #f2f2f2;
  border-radius: ${themeGet('borderRadius.1')};
  cursor: default;
`

export const From = styled(Box).attrs({
  display: 'block',
})`
  :after {
    color: ${themeGet('colors.text')};
    content: 'From';
    font-size: 14px;
    font-weight: normal;
  }
`

const roundedborderButtonCss = ({ roundedborder }) =>
  roundedborder &&
  css`
    border-radius: ${themeGet('borderRadius.2')} !important;
    overflow: hidden;
  `

export const StyledButton = styled(Button)`
  background-color: ${({ background }) =>
    background || themeGet('colors.goldenrod')};

  border: 1px solid
    ${({ background }) => background || themeGet('colors.goldenrod')};

  border-radius: ${themeGet('borderRadius.1')};
  color: ${({ color }) => color || themeGet('colors.black')};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  width: 100%;
  min-width: 120px;
  white-space: nowrap;
  height: unset;
  padding: 12px;

  @media screen and (max-width: 769px) {
    flex: 1;
  }

  transition:
    all 0.2s ease 0s,
    -webkit-transform 0.2s ease 0s;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 9px 2px;
    transition:
      all 0.2s ease 0s,
      -webkit-transform 0.2s ease 0s;
  }

  ${roundedborderButtonCss};
`

export const SocialContainer = styled(Flex).attrs({
  mt: 3,
  p: 4,
})`
  background-color: ${themeGet('colors.white')};
  border: 1px solid ${themeGet('colors.lightGrey')};
  border-radius: ${themeGet('borderRadius.1')};
`
